<template>
  <v-card>
    <v-card-title
      color="teal"
      dark
    >
      {{ existingRun && existingRun.uid ? $t('editRun') : $t('addRun') }}
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="runDetails.name"
              :label="$t('name')"
              color="teal"
              flat
              :rules="[rules.required]"
              outlined
            />

            <v-select
              v-model="runDetails.customFields.configurations"
              class="my-6 text-left"
              :items="testConfigurationOptions"
              :label="$t('configurations')"
              :menu-props="{ bottom: true, offsetY: true, contentClass: 'text-left' }"
              multiple
              outlined
              chips
              clear-icon
              clearable
              deletable-chips
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-radio-group v-model="testCasesSelection">
              <v-radio
                :label="$t('includeAllTestCases')"
                value="all"
              />
              <v-radio
                :label="$t('selectCasesToInclude')"
                value="select"
              />
            </v-radio-group>

            <div
              v-if="testCasesSelection === 'select'"
              class="my-2"
            >
              <v-btn
                x-small
                link
                text
                color="primary"
                @click="openCaseSelector"
              >
                {{ caseSelectionLabel }}
                ({{ $t('change') }})
              </v-btn>

              <v-dialog
                v-model="showCaseSelectDialog"
                persistent
                max-width="75%"
              >
                <CaseSelector
                  :existing-selected-cases="runDetails.customFields.selectedCases"
                  @cancel="closeCaseSelector"
                  @cases-selected="handleCaseSelection"
                />
              </v-dialog>
            </div>

            <IconDropdownSelector
              :items="runStatusList"
              :selected-item.sync="runDetails.customFields.state"
              :label="$t('state')"
              @update:selectedItem="(newItem) => (runDetails.customFields.state = newItem)"
            />

            <v-combobox
              v-model="runDetails.customFields.issues"
              :items="issues"
              :label="$t('issues')"
              attach
              dense
              outlined
              class="my-3"
              hide-details
              multiple
              small-chips
            />

            <v-combobox
              v-model="runDetails.customFields.tags"
              class="my-3"
              :items="tags"
              :label="$t('tags')"
              multiple
              persistent-hint
              small-chips
              attach
              dense
              outlined
              hide-details
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-html="$t('pressEnterToCreate')" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>

        <v-row justify="end">
          <v-btn
            color="success"
            depressed
            class="mr-4"
            @click="saveRun"
          >
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              size="20"
              color="white"
            />
            <span v-else>{{ $t('save') }}</span>
          </v-btn>
          <v-btn
            color="grey lighten-2"
            depressed
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { runStateMap, testConfigurationOptions } from '@/constants/grid';
import CaseSelector from '@/views/Tests/Shared/CaseSelector.vue';
import IconDropdownSelector from '@/components/Form/IconDropdownSelector.vue';

export default {
  components: { IconDropdownSelector, CaseSelector },
  props: {
    existingRun: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      runStatusList: Object.values(runStateMap),
      showCaseSelectDialog: false,
      testCasesSelection: 'all',
      runDetails: this.initializeRunDetails(),
      issues: this.issues,
      tags: this.tags,
      rules: {
        required: (value) => !!value || this.$t('fieldRequired'),
      },
      testConfigurationOptions,
    };
  },
  computed: {
    caseSelectionLabel() {
      const count = this.runDetails.customFields.selectedCases.length;
      return count > 0 ? `${count} ${this.$t('casesSelected')}` : this.$t('noCasesSelected');
    },
  },

  watch: {
    existingRun: {
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          this.runDetails = {
            ...this.runDetails,
            ...newValue,
            customFields: {
              ...this.runDetails.customFields,
              ...newValue.customFields,
              state: this.convertStringToObj(newValue.customFields.state, runStateMap, 'New')
            },
          };
          
          this.testCasesSelection = newValue.customFields.selectedCases === 'all' ? 'all' : 'select';
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.runDetails = this.initializeRunDetails();
  },
  methods: {
    saveRun() {
      if (!this.$refs.form.validate()) return;
      const runData = this.prepareRunDataForSave();
      this.$emit(this.existingRun ? 'update' : 'save', runData);
    },
    prepareRunDataForSave() {
      return {
        ...this.runDetails,
        customFields: {
          ...this.runDetails.customFields,
          state: this.runDetails.customFields.state.text,
          selectedCases: this.testCasesSelection === 'all' ? 'all' : this.runDetails.customFields.selectedCases,
        },
        uid: this.existingRun?.uid,
      };
    },
    initializeRunDetails() {
      let defaultRunDetails = {
        name: '',
        customFields: {
          selectedCases: [],
          configurations: null,
          description: '',
          template: '',
          state: this.convertStringToObj('New', runStateMap, 'New'),
          estimate: '',
          issues: [],
          tags: [],
          priority: 'Normal',
          attachments: [],
        },
      };
      if (this.existingRun && Object.keys(this.existingRun).length > 0) {
        return {
          ...this.existingRun,
          customFields: {
            ...this.existingRun.customFields,
            state: this.convertStringToObj(this.existingRun.customFields.state, runStateMap, 'New'),
          },
        };
      }
      return defaultRunDetails;
    },
    openCaseSelector() {
      this.showCaseSelectDialog = true;
    },
    closeCaseSelector() {
      this.showCaseSelectDialog = false;
    },
    handleCaseSelection(selectedCases) {
      this.runDetails.customFields.selectedCases = selectedCases;
      this.closeCaseSelector();
    },
    convertStringToObj(stringValue, list, defaultValue) {
      return list[stringValue] || list[defaultValue];
    },

    cancel() {
      this.$emit('close');
    },
  },
};
</script>
